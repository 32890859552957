.Caducado{
    @include degradado1;
    @include todapantalla;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .Logocaducado{
        width: 150px;
        height: 150px;
        /*background-image: url(/IMG/warning.png);*/
        @include centrarimagen;
        background-size: 150px;
    }
    .mensaje{
        font-size: 20px;
        color: white;
        max-width: 300px;
    }
}