$alto1:60px;
$alto2:100px;
.CabezaMain{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 50%);
    display: flex;
    height: $alto1;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    @media screen and #{$tablet} {
        height: $alto2;
    }
    @media screen and #{$escritio} {
        left: 60px;
    }
    .Buscador{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        $radio:5px;
        .contenedor{
            width: calc(100% - 40px);
            height: 40px;
            display: flex;
            box-shadow: $sombra1;
            border-radius: $radio;
            .icono{
                background-color: white;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-left-radius: $radio;
                border-bottom-left-radius: $radio;
                img{
                    width: 25px;
                }
            }
            .Continput{
                flex: 1;
                input{
                    background-color: white;
                    width: 100%;
                    height: 100%;
                    border: none;
                    padding: 0px;
                    font-size: 22px;
                    border-bottom-right-radius: $radio;
                    border-top-right-radius: $radio;
                    color: #616161;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
    .Logo{
        display: none;
        /*background-image: url(/IMG/logoletras.svg);*/
        background-size: 85px;
        background-repeat: no-repeat;
        background-position: center;
        @media screen and #{$tablet} {
            display: block;
            width: $alto2;
        }
    }
}