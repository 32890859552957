@import '../../variables.scss';
.Cargando {
    @include degradado1;
    @include todapantalla;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mensaje{
        color: white;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .Logo {
        width: 150px;
        height: 200px;
        /*background-image: url(/IMG/logoletrasC.svg);*/
        background-size: 150px;
        @include centrarimagen;
    }

    .loader {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        perspective: 800px;

        .inner {
            position: absolute;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border-radius: 50%;

            &.one {
                left: 0%;
                top: 0%;
                animation: rotate-one 1s linear infinite;
                border-bottom: 3px solid #EFEFFA;
            }

            &.two {
                right: 0%;
                top: 0%;
                animation: rotate-two 1s linear infinite;
                border-right: 3px solid #EFEFFA;
            }

            &.three {
                right: 0%;
                bottom: 0%;
                animation: rotate-three 1s linear infinite;
                border-top: 3px solid #EFEFFA;
            }
        }
    }
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}