@import "../../../variables.scss";
#Pies {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  background-color: $color1;
  z-index: 100;
  display: flex;
  @media screen and #{$escritio} {
    display: none;
  }
  .AccionDrawer {
    flex: 1;
    max-width: 100px;
    position: relative;

    .AccionDrawer1 {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 20px;
      &:active {
        background-color: red;
      }
    }
    .AccionDrawer2 {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 5px;
      background-color: $color1;
      &.activo{
        background-color: $color3;
      }
    }
  }
  .AccionSistema {
    flex: 1;
    max-width: 100px;
    position: relative;
    .AccionSistema1{
        position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 20px;
    }
    &.girar {
      animation: spin 2s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
