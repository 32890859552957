@import "../../../variables.scss";
#Listado{
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 0px;
    right: 0px;
    overflow-y: auto;
    @media screen and #{$tablet}{
        top: 100px;
    }
    @media screen and #{$escritio}{
        top: 100px;
        left: 60px;
        bottom: 0px;
    }
    $retraso:250ms;
    .Item{
        $alto:80px;
        background-color: white;
        height: $alto;
        box-sizing: border-box;
        border-bottom: 1px solid lightgrey;
        .DatosEstudio{
            height: 100%;
            transition: $retraso;
            .DatosEstudio1{
                display: flex;
                height: 100%;
                .DatosEstudio11{
                    width: $alto;
                }
                .DatosEstudio12{
                    .DatosEstudio121{
                        font-weight: bold;
                        font-size: 20px;
                        padding-top: 10px;
                        overflow: hidden;
                        height: 25px;
                    }
                    .DatosEstudio122{
                        font-size: 15px;
                    }
                    .DatosEstudio123{
                        font-size: 15px;
                    }
                    flex: 1;
                }
                .DatosEstudio13{
                    width: 40px;
                    display: flex;
                }
            }
        }
        .Herramientas{
            height: 0px;
            white-space: nowrap;
            overflow: hidden;
            transition: $retraso;
            .Herramientas1{
                display: flex;
                height: 0px;
                .Herramientas11{
                    flex: 1;
                }
                .Herramientas12{
                    width: 40px;
                    display: flex;
                }
            }
        }
        &.activo{
            .DatosEstudio{
                height: 0px;
                white-space: nowrap;
                overflow: hidden;
                transition: $retraso;
            }
            .Herramientas{
                background-color: whitesmoke;
                height: 100%;
                white-space: normal;
                overflow: initial;
                transition: $retraso;
                .Herramientas1{
                    display: flex;
                    height: 100%;
                    .Herramientas11{
                        flex: 1;
                        display: grid;
                        grid-template-rows: 20px 1fr;
                        .Herramientas111{
                            font-size: 18px;
                            font-weight: bold;
                            box-sizing: border-box;
                            padding-left: 10px;
                        }
                        .Herramientas112{
                            display: flex;
                            .IconoHerramientas{
                                flex: 1;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                    .Herramientas12{
                        width: 40px;
                        display: flex;
                    }
                }
            }
        }
    }
}