@import "../../../variables.scss";
#SideBar {
  $color:$color1;
  display: none;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 60px;
  background-color: $color;
  z-index: 100;
  @media screen and #{$escritio} {
    display: flex;
    flex-direction: column;
  }
  .AccionDrawer {
    height: 60px;
    position: relative;
    .AccionDrawer1 {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 10px;
      &:active {
        background-color: red;
      }
    }
    .AccionDrawer2 {
      $color:$color1;
      $colorActivo:$color3;
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 5px;
      right: 0px;
      background-color: $color;
      &.activo{
        background-color: $colorActivo;
      }
    }
  }
  .AccionSistema {
    height: 60px;
    position: relative;
    .AccionSistema1{
        position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
      padding: 10px;
    }
      &.girar{
        animation: spin 2s linear infinite;
      }
  }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }