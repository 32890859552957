$color1: var(--primary);
$color2: var(--secondary);
$color3: var(--tertiary);
$sombra1: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
@mixin degradado1 {
    background: var(--primary);
    background: radial-gradient(circle, var(--primary) 0%, var(--primary) 100%);
}
@mixin todapantalla {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
@mixin centrarimagen {
    background-position: center;
    background-repeat: no-repeat;
}
$tablet : '(min-width: 768px)';
$escritio : '(min-width: 992px)';