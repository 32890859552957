.Upload{
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: grid;
    grid-template-rows: 1fr 100px;
    .Upload1{
        display: flex;
        justify-content: center;
        align-items: center;
        .Upload11{
            width: 250px;
            height: 250px;
            border: 2px dashed black;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        input{
            display: none;
        }
    }
    .Upload2{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}