.Share{
    position: absolute;
top: 0px;
bottom: 0px;
left: 0px;
right: 0px;
$alto:40px;
.contenedor{
    position: absolute;
    top: 0px;
    bottom: $alto;
    left: 0px;
    right: 0px;
    background-color: white;
    overflow-y: auto;
    .inputdata{
        padding-top: 100px;
        padding-bottom: 10px;
        .contenedorinput{
            display: flex;
            justify-content: center;
            padding-top: 10px;
            padding-bottom: 20px;
            &.QR{
                padding-top: 10px;
            }
            .inputitem{
                width: calc(100% - 30px);
                height: 30px;
                font-size: 18px;
                border: none;
                border-bottom: 1px solid $color1;
                &:focus{
                    outline: none;
                }
            }
            .inputitem2{
                width: 60px;
                height: 30px;
                font-size: 18px;
                border: none;
                border-bottom: 1px solid $color1;
                text-align: center;
                &:focus{
                    outline: none;
                }
            }
            .Subtitulo {
                display: flex;
                align-items: center;
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }
}
.Selector{
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: $alto;
    display: flex;
    background-color: $color1;
    color: text-clr($color1);
    .BtnShare{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .iconcont{
            width: 30px;
        }
        &:active{
            background-color: red;
        }
    }
}
}