@import "../../../variables.scss";
#Drawer{
    position: absolute;
    height: 0px;
    left: 0px;
    right: 0px;
    bottom: 60px;
    z-index: 200;
    @media screen and #{$escritio}{
        top: 0px;
        bottom: 0px;
        left: 60px;
        width: 0px;
        height: 100%;
    }
    &.activo{
        height: calc(100% - 60px);
        @media screen and #{$escritio}{
            top: 0px;
            bottom: 0px;
            left: 60px;
            width: calc(100% - 60px);
            height: 100%;
        }
    }
    .Caja{
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 0px;
        background-color: white;
        z-index: 220;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: 250ms;
        white-space: nowrap;
        overflow: hidden;
        @import "./OpcionesDrawer.scss";
        .CerrarDrawer{
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 24px;
        }
        @media screen and #{$escritio}{
            top: 0px;
            bottom: 0px;
            left: 0px;
            height: 100%;
            width: 0px;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 5px;
            @import "./OpcionesDrawer.scss";
            .CerrarDrawer{
                display: none;
            }
        }
        &.activo{
            height: calc(100% - 8px);
            white-space: normal;
            overflow: initial;
            @media screen and #{$escritio}{
                height: 100%;
                width: 300px;
            }
        }
    }
    .Velo{
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 0px;
        right: 0px;
        background-color: #0000006c;
        z-index: 210;
        opacity: 0;
        transition: 250ms;
        &.activo{
            opacity: 1;
        }
    }
}