@import "../../../variables.scss";
$trans:250ms;
#DialogN{
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    display: none;
    justify-content: center;
    align-items: center;
    &.activo{
        display: flex;
    }
    @media screen and #{$tablet}{
        width: 500px;
        left: calc(50% - 250px);
        right: auto;
    }
    .Velo{
        background-color: #00000056;
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 100;
        opacity: 0;
        transition: $trans;
        z-index: 105;
        &.activo{
            opacity: 1;
        }
    }
    .Box{
        background-color: white;
        width: 100%;
        height: 500px;
        z-index: 110;
        position: relative;
        .CloseDialog{
            position: absolute;
            top: 0px;
            right: 0px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 120;
            user-select: none;
        }
        .Contenido{
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            z-index: 115;
            @import "./Share.scss";
            @import "./Uploadfile.scss";
            /////////////////
            .Agenda{
                position: absolute;
                top: 40px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                overflow-y: auto;
                .TarjetaContacto{
                    background-color: whitesmoke;
                    display: grid;
                    grid-template-columns: 1fr 80px;
                    .TarjetaContacto1{
                        box-sizing: border-box;
                        padding-left: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    .TarjetaContacto2{
                        box-sizing: border-box;
                        padding-top: 2px;
                        padding-bottom: 2px;
                        border-left: 1px solid black;
                        .ShareM{
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 2px;
                            svg{
                                width: 30px;
                            }
                            &:active{
                                background-color: red;
                            }
                        }
                    }
                }
            }
            ////////////////
        }
    }
}
.Boton {
    $color:$color3;
  height: 40px;
  width: calc(100% - 30px);
  border-radius: 5px;
  border: none;
  box-shadow: $sombra1;
  background-color: $color;
  color: text-clr($color);
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  &:focus{
      outline: none;
  }
  &:active{
      background-color: $color;
      box-shadow: none;
  }
  &:disabled{
        background-color: lighten($color: #c4c4c4, $amount: 10%);
        box-shadow: none;
        color: darken($color: #c4c4c4, $amount: 15%);
  }
}