.App {
    *{
        font-family: 'Roboto', sans-serif;
    }
}

.ConError{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('./assets/symphonybackground.jpg') no-repeat center center fixed;
    background-size: cover;
    &>.contenedor{
        background-color: rgba(207, 207, 207, 0.456);
        backdrop-filter: blur(5px);
        border: #d6d1d1 1px solid;
        width: 500px;
        height: 550px;
        
        //box-shadow: 0px 0px 10px 0px rgb(214, 216, 218);
        border-radius: 15px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 500px){
            width: 100%;
            height: 100%;
            max-height: 500px;
            box-shadow: none;
        }
        &>.imagen{
            &>img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &>.texto{
            margin-top: 20px;
            font-size: 30px;
            text-align: center;
            font-weight: bold;
        }
        &>.exp{
            margin-top: 20px;
            font-size: 20px;
            text-align: center;
            color: #545454;
        }
    }
}